/* tslint:disable */
/* eslint-disable */
/**
 * Cégeljárás Portál API
 * Cégeljárás Portál RESTful API
 *
 * The version of the OpenAPI document: 1.0.41-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AnnouncementDto } from '../models';
// @ts-ignore
import { FileResponse } from '../models';
// @ts-ignore
import { PagedAnnouncementRequest } from '../models';
// @ts-ignore
import { PagedResponseAnnouncementDto } from '../models';
// @ts-ignore
import { SignerSessionResult } from '../models';
/**
 * AnnouncementControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnnouncement: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadAnnouncement', 'id', id)
            const localVarPath = `/announcement/{id}/downloadAnnouncement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInjunction: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInjunction', 'id', id)
            const localVarPath = `/announcement/{id}/downloadInjunction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnouncement', 'id', id)
            const localVarPath = `/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PagedAnnouncementRequest} pagedAnnouncementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnnouncements: async (pagedAnnouncementRequest: PagedAnnouncementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedAnnouncementRequest' is not null or undefined
            assertParamExists('listAnnouncements', 'pagedAnnouncementRequest', pagedAnnouncementRequest)
            const localVarPath = `/announcement/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedAnnouncementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSigningSessionForAnnouncements: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('startSigningSessionForAnnouncements', 'requestBody', requestBody)
            const localVarPath = `/announcement/signingSessionForAnnouncements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementControllerApi - functional programming interface
 * @export
 */
export const AnnouncementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAnnouncement(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAnnouncement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInjunction(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInjunction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncement(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PagedAnnouncementRequest} pagedAnnouncementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAnnouncements(pagedAnnouncementRequest: PagedAnnouncementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseAnnouncementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAnnouncements(pagedAnnouncementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSigningSessionForAnnouncements(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignerSessionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSigningSessionForAnnouncements(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementControllerApi - factory interface
 * @export
 */
export const AnnouncementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnnouncement(id: number, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.downloadAnnouncement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInjunction(id: number, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.downloadInjunction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement(id: number, options?: any): AxiosPromise<AnnouncementDto> {
            return localVarFp.getAnnouncement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PagedAnnouncementRequest} pagedAnnouncementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnnouncements(pagedAnnouncementRequest: PagedAnnouncementRequest, options?: any): AxiosPromise<PagedResponseAnnouncementDto> {
            return localVarFp.listAnnouncements(pagedAnnouncementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSigningSessionForAnnouncements(requestBody: Array<number>, options?: any): AxiosPromise<SignerSessionResult> {
            return localVarFp.startSigningSessionForAnnouncements(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementControllerApi - object-oriented interface
 * @export
 * @class AnnouncementControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public downloadAnnouncement(id: number, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).downloadAnnouncement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public downloadInjunction(id: number, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).downloadInjunction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getAnnouncement(id: number, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).getAnnouncement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PagedAnnouncementRequest} pagedAnnouncementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public listAnnouncements(pagedAnnouncementRequest: PagedAnnouncementRequest, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).listAnnouncements(pagedAnnouncementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public startSigningSessionForAnnouncements(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AnnouncementControllerApiFp(this.configuration).startSigningSessionForAnnouncements(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
