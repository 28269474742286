/* tslint:disable */
/* eslint-disable */
/**
 * Cégeljárás Portál API
 * Cégeljárás Portál RESTful API
 *
 * The version of the OpenAPI document: 1.0.41-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmailRegistrationRequest } from '../models';
// @ts-ignore
import { PagedRequest } from '../models';
// @ts-ignore
import { PagedResponseEmailAddressDto } from '../models';
/**
 * EmailAddressControllerApi - axios parameter creator
 * @export
 */
export const EmailAddressControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} emailRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRegistration: async (emailRegistrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailRegistrationId' is not null or undefined
            assertParamExists('confirmRegistration', 'emailRegistrationId', emailRegistrationId)
            const localVarPath = `/email/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailRegistrationId !== undefined) {
                localVarQueryParameter['emailRegistrationId'] = emailRegistrationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmail', 'id', id)
            const localVarPath = `/email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmailAddress: async (pagedRequest: PagedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('listEmailAddress', 'pagedRequest', pagedRequest)
            const localVarPath = `/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailRegistrationRequest} emailRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerEmailAddress: async (emailRegistrationRequest: EmailRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailRegistrationRequest' is not null or undefined
            assertParamExists('registerEmailAddress', 'emailRegistrationRequest', emailRegistrationRequest)
            const localVarPath = `/email/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailAddressControllerApi - functional programming interface
 * @export
 */
export const EmailAddressControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailAddressControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} emailRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmRegistration(emailRegistrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmRegistration(emailRegistrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmailAddress(pagedRequest: PagedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseEmailAddressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmailAddress(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailRegistrationRequest} emailRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerEmailAddress(emailRegistrationRequest: EmailRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerEmailAddress(emailRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailAddressControllerApi - factory interface
 * @export
 */
export const EmailAddressControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailAddressControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} emailRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRegistration(emailRegistrationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.confirmRegistration(emailRegistrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmailAddress(pagedRequest: PagedRequest, options?: any): AxiosPromise<PagedResponseEmailAddressDto> {
            return localVarFp.listEmailAddress(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailRegistrationRequest} emailRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerEmailAddress(emailRegistrationRequest: EmailRegistrationRequest, options?: any): AxiosPromise<object> {
            return localVarFp.registerEmailAddress(emailRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailAddressControllerApi - object-oriented interface
 * @export
 * @class EmailAddressControllerApi
 * @extends {BaseAPI}
 */
export class EmailAddressControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} emailRegistrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAddressControllerApi
     */
    public confirmRegistration(emailRegistrationId: string, options?: AxiosRequestConfig) {
        return EmailAddressControllerApiFp(this.configuration).confirmRegistration(emailRegistrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAddressControllerApi
     */
    public deleteEmail(id: number, options?: AxiosRequestConfig) {
        return EmailAddressControllerApiFp(this.configuration).deleteEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PagedRequest} pagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAddressControllerApi
     */
    public listEmailAddress(pagedRequest: PagedRequest, options?: AxiosRequestConfig) {
        return EmailAddressControllerApiFp(this.configuration).listEmailAddress(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailRegistrationRequest} emailRegistrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAddressControllerApi
     */
    public registerEmailAddress(emailRegistrationRequest: EmailRegistrationRequest, options?: AxiosRequestConfig) {
        return EmailAddressControllerApiFp(this.configuration).registerEmailAddress(emailRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
