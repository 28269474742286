/* tslint:disable */
/* eslint-disable */
/**
 * Cégeljárás Portál API
 * Cégeljárás Portál RESTful API
 *
 * The version of the OpenAPI document: 1.0.41-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileResponse } from '../models';
// @ts-ignore
import { ParamsForSignedHash } from '../models';
// @ts-ignore
import { ParamsForUnsignedHash } from '../models';
// @ts-ignore
import { SignerSessionResult } from '../models';
// @ts-ignore
import { SigningResult } from '../models';
// @ts-ignore
import { TakeoverData } from '../models';
/**
 * MicroSignerControllerApi - axios parameter creator
 * @export
 */
export const MicroSignerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('cancel', 'sessionId', sessionId)
            const localVarPath = `/api/microsigner/cancel/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParamsForUnsignedHash} paramsForUnsignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSetUnsignedHash: async (paramsForUnsignedHash: ParamsForUnsignedHash, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramsForUnsignedHash' is not null or undefined
            assertParamExists('createAndSetUnsignedHash', 'paramsForUnsignedHash', paramsForUnsignedHash)
            const localVarPath = `/api/microsigner/setUnsignedHash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paramsForUnsignedHash, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('download', 'sessionId', sessionId)
            const localVarPath = `/api/microsigner/download/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/microsigner/baseUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParamsForSignedHash} paramsForSignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSignedHash: async (paramsForSignedHash: ParamsForSignedHash, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramsForSignedHash' is not null or undefined
            assertParamExists('setSignedHash', 'paramsForSignedHash', paramsForSignedHash)
            const localVarPath = `/api/microsigner/setSignedHash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paramsForSignedHash, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TakeoverData} takeoverData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSigningSessionForTakeover: async (takeoverData: TakeoverData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverData' is not null or undefined
            assertParamExists('startSigningSessionForTakeover', 'takeoverData', takeoverData)
            const localVarPath = `/api/microsigner/signingSessionForTakeoverData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(takeoverData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MicroSignerControllerApi - functional programming interface
 * @export
 */
export const MicroSignerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MicroSignerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParamsForUnsignedHash} paramsForUnsignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndSetUnsignedHash(paramsForUnsignedHash: ParamsForUnsignedHash, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndSetUnsignedHash(paramsForUnsignedHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaseUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParamsForSignedHash} paramsForSignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSignedHash(paramsForSignedHash: ParamsForSignedHash, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SigningResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSignedHash(paramsForSignedHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TakeoverData} takeoverData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSigningSessionForTakeover(takeoverData: TakeoverData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignerSessionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSigningSessionForTakeover(takeoverData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MicroSignerControllerApi - factory interface
 * @export
 */
export const MicroSignerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MicroSignerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParamsForUnsignedHash} paramsForUnsignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSetUnsignedHash(paramsForUnsignedHash: ParamsForUnsignedHash, options?: any): AxiosPromise<void> {
            return localVarFp.createAndSetUnsignedHash(paramsForUnsignedHash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(sessionId: string, options?: any): AxiosPromise<Array<FileResponse>> {
            return localVarFp.download(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseUrl(options?: any): AxiosPromise<string> {
            return localVarFp.getBaseUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParamsForSignedHash} paramsForSignedHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSignedHash(paramsForSignedHash: ParamsForSignedHash, options?: any): AxiosPromise<SigningResult> {
            return localVarFp.setSignedHash(paramsForSignedHash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TakeoverData} takeoverData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSigningSessionForTakeover(takeoverData: TakeoverData, options?: any): AxiosPromise<SignerSessionResult> {
            return localVarFp.startSigningSessionForTakeover(takeoverData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MicroSignerControllerApi - object-oriented interface
 * @export
 * @class MicroSignerControllerApi
 * @extends {BaseAPI}
 */
export class MicroSignerControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public cancel(sessionId: string, options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).cancel(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParamsForUnsignedHash} paramsForUnsignedHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public createAndSetUnsignedHash(paramsForUnsignedHash: ParamsForUnsignedHash, options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).createAndSetUnsignedHash(paramsForUnsignedHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public download(sessionId: string, options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).download(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public getBaseUrl(options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).getBaseUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParamsForSignedHash} paramsForSignedHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public setSignedHash(paramsForSignedHash: ParamsForSignedHash, options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).setSignedHash(paramsForSignedHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TakeoverData} takeoverData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroSignerControllerApi
     */
    public startSigningSessionForTakeover(takeoverData: TakeoverData, options?: AxiosRequestConfig) {
        return MicroSignerControllerApiFp(this.configuration).startSigningSessionForTakeover(takeoverData, options).then((request) => request(this.axios, this.basePath));
    }
}
