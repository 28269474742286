/* tslint:disable */
/* eslint-disable */
/**
 * Cégeljárás Portál API
 * Cégeljárás Portál RESTful API
 *
 * The version of the OpenAPI document: 1.0.41-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActiveEmailAddressListResponse } from '../models';
// @ts-ignore
import { DocumentProcessingResponse } from '../models';
// @ts-ignore
import { FileResponse } from '../models';
// @ts-ignore
import { NotificationDetailsDto } from '../models';
// @ts-ignore
import { PagedRequest } from '../models';
// @ts-ignore
import { PagedResponseAnnouncementDto } from '../models';
// @ts-ignore
import { PagedResponseNotificationDto } from '../models';
// @ts-ignore
import { RegistryCourtsResponse } from '../models';
/**
 * SubmissionControllerApi - axios parameter creator
 * @export
 */
export const SubmissionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadFile', 'id', id)
            const localVarPath = `/submissions/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements: async (id: number, pagedRequest: PagedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnouncements', 'id', id)
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('getAnnouncements', 'pagedRequest', pagedRequest)
            const localVarPath = `/submissions/{id}/announcements`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubmission', 'id', id)
            const localVarPath = `/submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} sender 
         * @param {string} recipient 
         * @param {string} subject 
         * @param {boolean} sendMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileSubmission: async (file: File, sender: string, recipient: string, subject: string, sendMail: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('handleFileSubmission', 'file', file)
            // verify required parameter 'sender' is not null or undefined
            assertParamExists('handleFileSubmission', 'sender', sender)
            // verify required parameter 'recipient' is not null or undefined
            assertParamExists('handleFileSubmission', 'recipient', recipient)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('handleFileSubmission', 'subject', subject)
            // verify required parameter 'sendMail' is not null or undefined
            assertParamExists('handleFileSubmission', 'sendMail', sendMail)
            const localVarPath = `/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (sender !== undefined) { 
                localVarFormParams.append('sender', sender as any);
            }
    
            if (recipient !== undefined) { 
                localVarFormParams.append('recipient', recipient as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('subject', subject as any);
            }
    
            if (sendMail !== undefined) { 
                localVarFormParams.append('sendMail', sendMail as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveEmailAddress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/sender-email-addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/recipient-addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSubmissions: async (pagedRequest: PagedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('retrieveSubmissions', 'pagedRequest', pagedRequest)
            const localVarPath = `/submissions/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndGetDataFromSubmission: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateAndGetDataFromSubmission', 'file', file)
            const localVarPath = `/submissions/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionControllerApi - functional programming interface
 * @export
 */
export const SubmissionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubmissionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncements(id: number, pagedRequest: PagedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseAnnouncementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncements(id, pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubmission(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubmission(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} sender 
         * @param {string} recipient 
         * @param {string} subject 
         * @param {boolean} sendMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFileSubmission(file: File, sender: string, recipient: string, subject: string, sendMail: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFileSubmission(file, sender, recipient, subject, sendMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveEmailAddress(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveEmailAddressListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveEmailAddress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCourts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistryCourtsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCourts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSubmissions(pagedRequest: PagedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSubmissions(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAndGetDataFromSubmission(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentProcessingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAndGetDataFromSubmission(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubmissionControllerApi - factory interface
 * @export
 */
export const SubmissionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubmissionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(id: number, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.downloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements(id: number, pagedRequest: PagedRequest, options?: any): AxiosPromise<PagedResponseAnnouncementDto> {
            return localVarFp.getAnnouncements(id, pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission(id: number, options?: any): AxiosPromise<NotificationDetailsDto> {
            return localVarFp.getSubmission(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} sender 
         * @param {string} recipient 
         * @param {string} subject 
         * @param {boolean} sendMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileSubmission(file: File, sender: string, recipient: string, subject: string, sendMail: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.handleFileSubmission(file, sender, recipient, subject, sendMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveEmailAddress(options?: any): AxiosPromise<ActiveEmailAddressListResponse> {
            return localVarFp.listActiveEmailAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourts(options?: any): AxiosPromise<RegistryCourtsResponse> {
            return localVarFp.listCourts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PagedRequest} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSubmissions(pagedRequest: PagedRequest, options?: any): AxiosPromise<PagedResponseNotificationDto> {
            return localVarFp.retrieveSubmissions(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndGetDataFromSubmission(file: File, options?: any): AxiosPromise<DocumentProcessingResponse> {
            return localVarFp.validateAndGetDataFromSubmission(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubmissionControllerApi - object-oriented interface
 * @export
 * @class SubmissionControllerApi
 * @extends {BaseAPI}
 */
export class SubmissionControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public downloadFile(id: number, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).downloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PagedRequest} pagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public getAnnouncements(id: number, pagedRequest: PagedRequest, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).getAnnouncements(id, pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public getSubmission(id: number, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).getSubmission(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} sender 
     * @param {string} recipient 
     * @param {string} subject 
     * @param {boolean} sendMail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public handleFileSubmission(file: File, sender: string, recipient: string, subject: string, sendMail: boolean, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).handleFileSubmission(file, sender, recipient, subject, sendMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public listActiveEmailAddress(options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).listActiveEmailAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public listCourts(options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).listCourts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PagedRequest} pagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public retrieveSubmissions(pagedRequest: PagedRequest, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).retrieveSubmissions(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionControllerApi
     */
    public validateAndGetDataFromSubmission(file: File, options?: AxiosRequestConfig) {
        return SubmissionControllerApiFp(this.configuration).validateAndGetDataFromSubmission(file, options).then((request) => request(this.axios, this.basePath));
    }
}
