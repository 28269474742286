/* tslint:disable */
/* eslint-disable */
/**
 * Cégeljárás Portál API
 * Cégeljárás Portál RESTful API
 *
 * The version of the OpenAPI document: 1.0.41-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";
export * from "./models";
